<template>
  <main>
    <div class="white">
      <div class="container">
        <div class="row no-margin flex">

          <div class="col s12 color-text">
            <h1>TGRAINS Ethics and Site Usage Statement</h1>
            <a href="/"
                class="btn green darken-2 waves-effect waves-green">
              <i class="material-icons">backspace</i> Return
            </a>
          </div>

          <div class="col s12 m8 push-m2">
            <h4>T-GRAINS: Transforming and Growing Relationships within regional food systems for Improved Nutrition and
              Sustainability</h4>
            <p>As a participant in this research project, you are taking part in a workshop about the future of the food
              system in your local area.
              This page provides information about the research, the workshop, and the type of data that will be
              captured.</p>
            <p>We hope it answers any questions that you may have, but if you do have any further queries please
              contact:</p>

            <pre>
              Samantha Finnigan
              Room 310
              Computer and Information Sciences Building
              Northumbria University
              Newcastle upon Tyne
              NE1 8ST

              Tel: (0191) 227 3944
              Email: samantha.finnigan@northumbria.ac.uk
            </pre>
          </div>

          <div class="col s12 m8 push-m2">
            <h5>What is the research about? What will my participation involve?</h5>
            <p>
            We are running a workshop on the roles which digital tools might play in understanding a regional food
            system, and what roles, from your perspective, they might have in connecting people within a region.
            </p><p>
            Our global food system is unsustainable, impacting public health, burdening the environment and adding
            significant costs to the economy. Much remains to be done to foster healthy diets and improve stewardship of
            food production landscapes, and there is an urgent need to understand the feasibility and potential of
            alternative “place-based” approaches to the food system: where consumers are more connected to where their
            food comes from and understand the implications of their choices. We are investigating how these challenges
            might be addressed by reconnecting consumers and producers, and shaping consumption and production
            practices, through the use of digital tools.
            </p><p>
            The workshop will consist of a number of individual and group activities with a break. You will
            also be provided with a schedule of the activities.
            </p>
          </div>

          <div class="col s12 m8 push-m2">
            <h5>Why are we doing it?</h5>
            <p>
              Visions of sustainable food systems typically involve top-down, large-scale agriculture, with
              efficiency-based solutions to the problem of environmental sustainability. But this vision performs a very
              distinct version of sustainability that does not address the unsustainability inherent in global food
              supply networks, and leaves little room for citizen agency.
            </p><p>
              Through these workshops, we are interested in exploring the following goals in collaboration with food
              producers, retailers and consumers:
            </p>
            <ul>
              <li>
                Exploring mechanisms to foster relationships between regional food system stakeholders by discussing,
                describing, and playing with scenarios.
              </li><li>
                Developing joint understandings of the terms used by different stakeholders to articulate sustainable
              and healthy food practices, regional food, and the food system
              </li><li>
                Understanding different stakeholder perspectives on sustainable and healthy regional food (and food
                systems), and points of conflict and agreement
              </li><li>
                Understanding perceived constraints and barriers to regionally based-diets (i.e. milling, distribution).
              </li><li>
                Understanding stakeholder needs for a tool to co-create a regional food system
              </li><li>
                Understanding stakeholder needs for realising an agreed vision for a regional food system
              </li>
            </ul>
          </div>

          <div class="col s12 m8 push-m2">
            <h5>Confidentiality and anonymity:</h5>
            <p>
              Any information collected from you will be treated with confidentiality. This means that only the research
              team will have access to any raw information that can be specifically associated with you. Any information
              that is shared beyond this team will be made anonymous. This means that personal details such as your name
              will be removed, and a pseudonym will be used instead. This will apply to any publications or
              presentations or any discussion with other colleagues in the research institutions.
            </p>
          </div>

          <div class="col s12 m8 push-m2">
            <h5>How your data will be used and protected:</h5>
            <p>
              We will treat data that you provide or that is collected from your home in accordance with the Data
              Protection Act 2018, which enacts the General Data Protection Regulation (GDPR), and any subsequent data
              protection legislation. This means that any personal information stored in physical format (e.g., on
              paper) will be stored in a secure location, for example a locked office at the research institutions
              involved in this project. Any personal information stored electronically will be stored on a secure,
              password-protected computer or information processing system. Any personal information that is transported
              electronically on a mobile device (a laptop or memory stick) will be encrypted and/or password-protected.
              You have the right to withdraw your consent to data processing at any time.
            </p><p>
              The information collected will be used to inform the development of further research and may be included in
              anonymised form in publications, presentations and PhD theses. Anonymised information may be retained
              indefinitely for ongoing research purposes.
            </p><p>
              For further information, a full privacy notice for data collected as part of university research projects
              is available on the <a href="https://www.northumbria.ac.uk/about-us/leadership-governance/vice-chancellors-office/legal-services-team/gdpr/gdpr---privacy-notices/">
              Northumbria University website</a>.
            </p><p>
              This site stores data in your browser via a mechanism called 'local storage'. This is required to
              enable the application to return you to your previous state if the page is reloaded or refreshed. To
              clear this data from your local storage, you may either decline the privacy notice which is displayed when
              the page loads, or click 'Clear local storage' under the 'Technical data' link which you may find at the
              bottom of the page.
            </p>
          </div>

          <div class="col s12 m8 push-m2">
            <h5>About the researchers:</h5>
            <p>
              The research team are a cross-institution group, based at Cardiff University, Northumbria University,
              Rothamstead Research, Scotland’s Rural College (SRUC) and Newcastle University.
            </p>
            <h5>Research Team:</h5>
            <table>
              <tr>
                <td>Angelina Sanderson Bellamy	  </td><td>Cardiff University</td>
              </tr><tr>
                <td>Ella Furness 			            </td><td>Cardiff University</td>
              </tr><tr>
                <td>Adrian Clear 			            </td><td>Northumbria University</td>
              </tr><tr>
                <td>Samantha Finnigan 	        	</td><td>Northumbria University</td>
              </tr><tr>
                <td>Alice Milne 			            </td><td>Rothamstead Research</td>
              </tr><tr>
                <td>Ryan Sharp			              </td><td>Rothamstead Research</td>
              </tr><tr>
                <td>Elliot Meador 		          	</td><td>Scotland’s Rural College (SRUC)</td>
              </tr><tr>
                <td>Susanna Mills			            </td><td>Newcastle University</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ethics-statement'
}
</script>

<style scoped>
pre {
  font-family: Roboto, sans-serif;
}
ul > li {
  list-style: initial !important;
  list-style-type: initial !important;
  margin-left: 2rem;
}
</style>