import axios from "axios"

export default {
    /***
     * Helper function: use Promise to implement sleep()
     * @param sleepMs
     * @returns {Promise<unknown>}
     */
    sleep (sleepMs) {
        let res
        setTimeout(function(){ res() }, sleepMs)
        return new Promise(function(a){ res = a; })
    },

    /***
     * Check the /api/status/<job_id> route for the return from the Celery task queue
     * @param response
     * @returns {Promise<*>}
     */
    async checkCeleryStatus (response) {
        const pathname = new URL(response.request.responseURL).pathname
        // console.trace(response.config.url, response.data)

        // Keep checking the celery status if result is deferred, e.g. state= "PENDING", "STARTED", or "RETRY"
        while (!(['SUCCESS', 'FAILURE', 'REVOKED'].includes(response.data.state))) {
            await this.sleep(500)
            console.debug("Awake! Checking state again...", pathname)

            response = await axios.get(pathname)

            // Handle server mis-configuration (200 OK returned, but not a valid object)
            if(typeof response.data !== 'object')
                throw Error("The response did not contain valid JSON.")
            if(!('state' in response.data))
                throw Error("The response did not include a valid application state.")
        }

        if(response.data.state === 'SUCCESS') {
            return response
        } else {
            const regex = /(invalid_argument: )(ERROR: )(.+)$/gm
            let match = regex.exec(response.data.status)
            let errormessage = ""

            // Parse error messages from model
            if(Array.isArray(match) && match.length > 0) {
                match = match[match.length - 1]
                errormessage = match.charAt(0).toUpperCase() + match.slice(1) // Capitalise first letter
            } else {
                errormessage = `Query ${response.data.state} with message: ${response.data.status}`
            }

            console.error(response.data)
            throw errormessage
        }
    },

    /***
     * POST a query to the model, await response, and commit result to the Vuex store
     * @param data - formatted data for POSTing
     * @returns {Promise<boolean>}
     */
    async queryModel (data) {
        try {
            // Query the model and kick off celery task
            let response = await axios.post('/api/model', data)
            // console.log(response)

            response = await this.checkCeleryStatus(response)
            return response.data.result
        }
        catch (err) {
            console.error(err);
            throw err.toString() || ""
        }
    },

    /***
     * Load the Business As Usual state into the Vuex store
     * @returns {Promise<void>}
     */
    async loadBAUState (landscape_id) {
        try {
            const data = {params: {'landscape_id': landscape_id}}

            let response = await axios.get('/api/model', data)
            response = await this.checkCeleryStatus(response)

            console.info("BAU State Loaded!")
            return response.data.result
        }
        catch (err) {
            console.log(err)
            throw "Loading business-as-usual state failed. " + err.toString() || ""
        }
    },

    /***
     * Load strings for crop identifiers
     * @returns {Promise<void>}
     */
    async loadStrings (landscape_id) {
        try {
            const data = {params: {'landscape_id': landscape_id}}

            let response = await axios.get('/api/strings', data)
            response = await this.checkCeleryStatus(response)

            console.info("Crop & Livestock Strings Loaded!")
            return response.data.result
        }
        catch (err) {
            console.log(err)
            throw "Loading strings failed. " + err.toString() || ""
        }
    },

    /**
     * Load comments from the database.
     *
     * Route: /comment?page=1&size=10
     * Method: GET
     *
     * Get comments from the database in paginated form. The following params control the pagination:
     *
     * • page: Integer. The page to load.
     * • size: Integer. The size of a page.
     *
     * Comments are returned as JSON.
     *
     * NB: Page counter starts at 1. Requesting page 0 results in 404 not found (from flask-sqlalchemy)
     *
     * @param page
     * @param size
     * @param ...kwargs
     * @returns {Promise<any>}
     */
    async loadComments(page, size, {...kwargs} = {}) {
        try {
            const data = {
                params: {
                    'page': page,
                    'size': size,
                    ...kwargs
                }
            }

            let response = await axios.get('/api/comment', data)

            console.info("Loaded comments")
            return response.data
        }
        catch (err) {
            console.log(err)
            throw "Comment load failed. " + err.toString() || ""
        }
    },

    /**
     * Post a comment
     * Route: /comment?page=1&size=10
     * Method: POST
     *
     * Post a comment to the model, to be stored in the database. POST body MUST include the below variables, formatted as
     * JSON.
     *
     * • text: String. The comment body.
     * • user_id: String. The unique generated uid.
     * • author: String. The author's name.
     * • email: String. The author's email address (may be NULL for social media login)
     *
     * POST body MAY also include the following variable:
     *
     * • reply_id: Integer. The comment that this comment is replying to.
     *
     * @param data
     * @returns {Promise<*>}
     */
    async postComment(data) {
        try {
            let response = await axios.post('/api/comment', data)

            console.info("Comment posted")
            return response.data
        }
        catch (err) {
            console.error(err);
            throw "Comment post failed. " + err.toString() || ""
        }
    },

    /***
     * Retrieve tags from API
     * @returns {Promise<any>}
     */
    async loadTags() {
        try {
            let response = await axios.get('/api/tags')

            console.info("Loaded tags")
            return response.data
        }
        catch (err) {
            console.error(err);
            throw "Tags load failed. " + err.toString() || ""
        }
    },

    /***
     * Load a single reply from the database
     * @returns {Promise<void>}
     */
    async loadReply(reply_id) {
        try {
            const data = {params: {'id': reply_id}}
            let response = await axios.get('/api/reply', data)

            console.info("Loaded reply")
            return response.data
        }
        catch (err) {
            console.error(err);
            throw "Comment load failed. " + err.toString() || ""
        }
    },

    /**
     * Post a history state to the server
     * @param data
     * @returns {Promise<void>}
     */
    async postState(data) {
        try {
            let response = await axios.post('/api/state', data)
            console.info('Posted state to server')
            return response
        }
        catch (err) {
            console.error(err)
            throw "History POST failed. " + err.toString() || ''
        }
    },

    /**
     * Tell the server to fork a session
     * @param data {user_id, session_id, new_session_id}
     * @returns {Promise<void>}
     */
    async forkSession(data) {
        try {
            return await axios.post('/api/fork', data)
        }
        catch (err) {
            console.error(err)
            throw "History POST failed. " + err.toString() || ''
        }
    }
}