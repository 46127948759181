<template>
  <footer class="page-footer">
    <div class="container logos">
      <img alt="Project institution logos" class="logos" src="../assets/TGRAINS-institutions.svg">
    </div>
    <div class="footer-copyright">
      <div class="container">
        © 2020 TGRAINS
      </div>
    </div>
  </footer>
</template>
<script>
    export default {
        name: 'ContainerFooter'
    }
</script>
<style scoped>
  /* Footer */
  div.logos {
    text-align: center;
  }
  img.logos {
    max-height:100px;
  }
</style>